<template>
  <div class="mx-a mb-16">
    <div class="category-wrap">
      <div class="category-content-wrap">
        <div class="sort-div">
          <span class="sort-count"
            >내역
            <span style="color: #2CCDC3; font-weight: 700">{{ totCnt }}</span>
            건</span
          >
          <div class="d-flex">
            <select-box
              :opt-nm="SELT_CNT"
              v-model="params.numOfData"
              style="width: 120px; text-align: left"
            />
          </div>
        </div>
        <table class="search-table">
          <tr>
            <th v-for="(fld, fldIdx) in tblFld" :key="fldIdx">{{ fld }}</th>
          </tr>
          <tr v-for="(row, rowIdx) in tblRow" :key="rowIdx">
            <td style="width: 20%">{{ row.rDate }}</td>
            <td style="width: 20%">{{ row.eDate }}</td>
            <td style="width: 20%">{{ row.memo }}</td>
            <td style="width: 15%">{{ row.id }}</td>
            <td style="width: 15%">{{ row.pass }}</td>
            <td style="width: 10%">
              <button-common
                :size="SIZE_T"
                :clr="CLR_W"
                @click="goAuthDetail(row)"
                >보기</button-common
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemAuthList",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      params: {
        page: 1,
        numOfData: 10,
      },
      tblFld: [
        "만든일자",
        "수정일자",
        "계정담당",
        "아이디",
        "비밀번호",
        "설정",
      ],
      tblRow: [],
      totCnt: 0,
      toRoute: {
        path: "",
        meta: "",
      },
    };
  },

  /*** created ***/
  /*** created hook ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    await this.getData();
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE"]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (!val) {
        this.getData();
      }
    },
  },

  /*** methods ***/
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      const rs = await this.$axios({
        url: "/admin/account/data",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.tblRow = rs.resultList;
        this.totCnt = rs.totcnt;
      }
    },
    goAuthDetail(row) {
      this.$router.push({
        path: `/system/auth/add/` + row.esntlId,
        meta: { id: "16-1-1-1" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.category-wrap {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #d8dae5;
}
.category-header-wrap {
  width: 100%;
  border-bottom: 1px solid #d8dae5;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.category-content-wrap {
  padding: 50px 32px 80px 32px;
}
.sort-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.sort-count {
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
</style>
